// app/javascript/controllers/price_filter_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  submitForm(event) {
    let selectedPrices = []
    this.formTarget.querySelectorAll('input[type="checkbox"]:checked').forEach((checkbox) => {
      const priceRange = checkbox.value;
      if (priceRange !== "") {
        selectedPrices.push(priceRange);
      }
    });

    const params = new URLSearchParams();
    if (selectedPrices.length > 0) {
      params.append("prices", selectedPrices.join(","));
    }

    const url = `${this.formTarget.action}?${params.toString()}`;
    window.location.href = url;
  }

  trigger(event){
    this.submitForm();
  }
}
