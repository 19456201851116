import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "langselector" ]
  
  changeLang(){
    const defaultLangs = ['en', 'de', 'ar']
    const selectedLanguage = this.langselectorTarget.value;
    if (defaultLangs.includes(selectedLanguage)){
      location.href = "/" + selectedLanguage;
    }
  }
}
